'use client';

export type spacing =
  | 'small-spacing'
  | 'base-spacing'
  | 'spacing-medium'
  | 'spacing-large';

export const getSpacing = (s: spacing) => {
  if (typeof window === 'undefined') {
    return '';
  }

  return `${getComputedStyle(document.documentElement).getPropertyValue(
    `--${s}`
  )}`;
};
